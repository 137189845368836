import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    commonTextErrorSelector,
    commonTextSuccessSelector,
    commonTextWarningSelector,
    currentLanguageSelector,
} from 'store/translations/translations.selectors';
import { colorNames } from 'app/app.styles';

import { notificationBarSmallNotificationsSelector } from 'store/notification-bar-small/notification-bar-small.selectors';
import { deleteSmallAlert } from 'store/notification-bar-small/notification-bar-small.slice';
import { Slide } from '@progress/kendo-react-animation';
import { NotificationContainer, NotificationGroupContainer } from './notification-bar.styles';

export const NotificationTypes = {
    SUCCESS: 0,
    WARNING: 1,
    ERROR: 2,
};

const NotificationBarSmall = () => {
    const notifications = useSelector(notificationBarSmallNotificationsSelector);
    const userLang = useSelector(currentLanguageSelector);
    const warningText = useSelector(commonTextWarningSelector(userLang));
    const errorText = useSelector(commonTextErrorSelector(userLang));
    const successText = useSelector(commonTextSuccessSelector(userLang));

    const [notification, setNotification] = useState({ type: '', message: '' });
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const duration = notification.type === NotificationTypes.ERROR ? 8 : 4;
    const timeoutRef = useRef(null);

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        if (notifications.length > 0) {
            const notificationToProcess = notifications[notifications.length - 1];
            if (notificationToProcess.guid) {
                dispatch(deleteSmallAlert(notificationToProcess.guid));
            }

            setNotification(notificationToProcess);
            setShow(true);
        } else {
            timeoutRef.current = setTimeout(() => {
                setShow(false);
            }, duration * 1000);
        }
    }, [notifications, notification.type, notification.guid, dispatch, duration]);

    let defaultMessage = '';
    let icon = '';
    let color = '';

    switch (notification.type) {
        case NotificationTypes.ERROR:
            if (!notification.message) {
                defaultMessage = errorText;
            }
            color = colorNames.ZenDarkRed;
            icon = <span className='common-icon fa-regular fa-circle-xmark'></span>;
            break;
        case NotificationTypes.WARNING:
            if (!notification.message) {
                defaultMessage = warningText;
            }
            color = colorNames.ZenYellowLight;
            icon = <span className='common-icon fa-solid fa-circle-exclamation'></span>;
            break;
        case NotificationTypes.SUCCESS:
            if (!notification.message) {
                defaultMessage = successText;
            }
            color = colorNames.ZenGreenLight;
            icon = <span className='common-icon fa-regular fa-circle-check'></span>;
            break;
        default:
            defaultMessage = '';
    }

    return <NotificationGroupContainer
        key={notification.guid}
    >
        <Slide appear={true} direction={show ? 'up' : 'down'} transitionEnterDuration={800} transitionExitDuration={500}>
            {show
                && (<NotificationContainer
                    color={color}
                    type={{ style: 'none', icon: false }}
                >
                    {icon}
                    {notification.message || defaultMessage}
                </NotificationContainer>
                )}
        </Slide>
    </NotificationGroupContainer>;
};

export default NotificationBarSmall;
