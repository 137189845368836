import { colorNames } from 'app/app.styles';
import kendoCommonStyles from 'app/styles/kendo-components-common.scss';
import styled, { createGlobalStyle, css } from 'styled-components';

export const DropDownListGlobalStyle = createGlobalStyle`
    .drop-down-disabled-item {
        opacity: 0.7;
        cursor: default;
        pointer-events: none;
    }

    .k-list {
        .k-list-item.k-selected,
        .k-list-item:hover {
            background-color: var(${colorNames.ZenCyan});
            color: var(${colorNames.ZenFontCyan});
        }
    }
`;

export const sharedDropDownListStyles = css`
    .k-dropdownlist.k-picker {
        height: ${(props) => (props.height ? props.height : '30px')};
        font-size: inherit;
    }

    .k-dropdownlist {
        border: solid 1px var(${colorNames.ZenGrey});
        border-top-color: var(${colorNames.ZenGrey}) !important;
        border-radius: 0px;
        background-color: var(${colorNames.ZenWhite});
        button {
            .k-icon.k-svg-i-caret-alt-down {
                min-height: unset;
            }
        }
    }
    .k-dropdownlist:hover {
        background-color: var(${colorNames.ZenCyan});
        color: var(${colorNames.ZenFontCyan});
    }
`;

export const DropDownListWrapper = styled.div`
    ${sharedDropDownListStyles}
    ${kendoCommonStyles}
`;
